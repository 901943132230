.textarea-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
}

.textarea-container .entry {
    font-size: 1rem;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.textarea-container .entry .prompt {
    padding-right: 0.5rem;
    font-weight: 800;
}

.textarea-container .entry input{
    font-size: 1rem;
    text-align: left;
    width: 100%;
    background-color: transparent;
    border: none;
    color: inherit;
    outline: none;
    padding:0;
}

.textarea-container .entry input::placeholder {
    opacity: 0;
}

.textarea-container .entry input:focus::placeholder {
    opacity: 1;
}

.textarea-container .entry.response {
    margin-left: 0.5em;
    text-wrap: wrap;
    overflow: auto;
}
