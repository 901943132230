.gt-audition-container {
    background-image: var(--bg-gradient-shallow);
    padding: 20vh 5vw 10vh;
    width: calc(100% - 10vw);
    height: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    color: var(--text-color);
}

.gt-audition-container .gt-audition-text {
    background-color: var(--bg-color-glass);
    box-shadow: var(--default-shadow);
    width: 80%;
    padding: 1em 2em;
}

.gt-audition-picturelinks-container {
    margin: 1em 0;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
}

.gt-audition-picturelinks-container img {
    width: calc(100% - 2*1em);
    max-width: 500px;
    padding: 1em;
}

.gt-audition-container b {
    color: var(--accent-color-2)
}