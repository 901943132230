.contact-container {
    background-image: var(--bg-gradient-shallow);
    color: var(--text-color);
    height: 100%;
    min-height: 90vh;
    padding: 80px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.contact-container h2 {
    font-size: calc(1.2rem + 3vw);
    margin: 20px;
}

.contact-container-division {
    background-color: var(--bg-color-glass);
    box-shadow: var(--default-shadow);
    backdrop-filter: blur(4px);
    border-radius: 0.3em;
    margin: 2em;
    width: min(90vw, 500px);
    min-width: 300px;
    text-align: left;
    padding: 40px 40px;
}

.contact-container-division h2 {
    font-size: 2em;
    padding: 20px 0;
    margin: 0;
}

.contact-container-division p {
    font-size: calc(0.7rem + 0.5vw);
    font-weight: 400;
    padding: 0.2em 0 0;
    margin: 0;
}

.contact-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
}

.contact-button img {
    padding: 0 2rem;
    width: 55px;
}