.footer-container {
    background-color: #000011;
    width: calc(100% - 2*1.5em);
    padding: 1.5em;
    color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-container .copyright-notice {
    padding: 0.8rem 0 0;
}

.footer-container .copyright-notice span {
    color: var(--accent-color);
}