.terminal-container {
    position: absolute;
    background-color: #0f212d;
    border-radius: 0.5rem;
    margin: 20px;
    color: white;
    z-index: 3;
    box-shadow: var(--default-shadow);
}

.terminal-container.disabled {
    display: none;
}

.terminal-container .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    background-color: #09141c;
    border-radius: 0.5rem 0.5rem 0 0;   
    font-size: 1rem;
    padding: 0.5rem 1rem;
    user-select: none;
    cursor: move;
}

.terminal-container .text-container {
    color: white;
    height: 500px;
    width: 500px;
    max-width: 1000px;
    min-width: 300px;
    border: none;
    overflow: auto;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    resize: both;
    padding: 1rem 1rem 0.5rem;
    resize:both;
    overflow:auto;
}

.terminal-container.navy { background: #0f212d; }
.terminal-container.navy .header { background: #09141c; color: white; }
.terminal-container.navy .text-container { color: white; }
.terminal-container.navy .text-container::selection { background: white; color: black; }

.terminal-container.homebrew { background: #000000; }
.terminal-container.homebrew .header { background: #fffeea; color: black; }
.terminal-container.homebrew .text-container { color: rgb(0, 213, 35); }
.terminal-container.homebrew .text-container::selection { background: #ffffff; color: black; }

.terminal-container.basic { background: #ffffff; }
.terminal-container.basic .header { background: #d1ffff; color: black; }
.terminal-container.basic .text-container { color: black; }
.terminal-container.basic .text-container::selection { background: black; color: white; }


.terminal-container.florida { background: black; }
.terminal-container.florida .header { background: #0021a5; color: white; }
.terminal-container.florida .text-container { color: #fa4616; }
.terminal-container.florida .text-container::selection { background: #ffffff; color: black; }

.terminal-container.tech { background: #003057; }
.terminal-container.tech .header { background: #09141c; color: #EAAA00; }
.terminal-container.tech .text-container { color: #FFCD00; }
.terminal-container.tech .text-container::selection { background: #F9F6E5; color: black; }
