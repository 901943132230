.resume-view-container {
    background-image: var(--bg-gradient-shallow);
    padding: 20vh 0 10vh 0;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.resume-view-container .resume-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1em 3em;
    gap: 50px;
}

.resume-view-container .resume-page {
    opacity: 1;
    animation: slideup 2s forwards;
    margin-top: -100px;
    z-index: 5;
}

@keyframes slideup {
    from {
        opacity: 0;
        margin-top: -100px;
    }
    to {
        opacity: 1;
        margin-top: 100px;
    }
}

@media (max-width: 1000px) {
    .resume-view-container {
        min-height: calc(100vh - 19em);
    }

    .resume-view-container .resume-page {
        display: none;
    }
}