.dro-container {
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    padding: 1em 2em;
    background-color: black;
    border-radius: 0.5em;
    color: var(--code-green);
    font-size: calc(0.8em + 0.5vw);
    box-shadow: var(--default-shadow);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.dro-container div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
}

.dro-container .toggle-btn {
    background: none;
    border-bottom: 1px solid var(--code-green);;
	padding: 0;
	font: inherit;
    color: inherit;
}