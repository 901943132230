.slideshow-container {
    color: white;
    border-radius: 0.5rem;
    height: 260px;
    width: 100%;
}

.slideshow-container .img-container {
    height: 90%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slideshow-container .img-container img {
    height: 100%;
}

.slideshow-container .img-container .arrow {
    position: absolute;
    height: 30px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
}

.slideshow-container .img-container .arrow:hover {
    opacity: 1;
}

.slideshow-container .img-container .arrow.right-arrow {
    right: 10px;
}

.slideshow-container .img-container .arrow.left-arrow {
    transform: rotate(180deg);
    left: 10px;
}

.slideshow-container .image-selectors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 2em;
    width: calc(100% - 2*2em);
}

.slideshow-container .image-selectors .selector {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: var(--text-color);
    margin: 0.25em 0.5em;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
}

.slideshow-container .image-selectors .selector.active {
    opacity: 1;
}

.slideshow-container .image-selectors .selector:hover {
    opacity: 1;
}