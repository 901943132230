.button-container {
    color: var(--text-color);
    background-color: transparent;
    padding: 0.5em 1em;
    border-radius: 2em;
    font-size: 1.4em;
    font-weight: 800;
    border: 2px solid;
    cursor: pointer;
}

.button-container:hover {
    color: var(--accent-color);
}