.aboutme-container {
    background-color: var(--bg-color-extralight);
    box-shadow: var(--default-shadow);
    border-radius: 0.5em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
}

.aboutme-container .aboutme-col.text {
    font-size: calc(12.5px + 0.5vw);
    text-align: left;
    min-width: 250px;
    width: 60%;
}

.aboutme-container .aboutme-col.text p span {
    font-weight: 600;
    color: var(--accent-color-2);
}

.aboutme-container .aboutme-col.picture img {
    width: calc(10*(12.5px + 0.5vw));
    border-radius: 100%;
    user-select: none;
    margin: 0em 50px;
    /* border: 2px solid black; */
}

@media (max-width: 1200px) {
    .aboutme-container .aboutme-col.text {
        width: 80%;
    }
}